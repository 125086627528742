import { alertController, toastController } from '@ionic/vue';


const consola = function(){ void(0) }; //consola

    const openToast= async function (head, msg, tiempo) {
        const toast = await toastController
            .create({
            header: head,
            message: msg,
            duration: tiempo ===undefined ? 3000 : tiempo,
            position: 'middle',
            buttons: [
                {
                side: 'end',
                text: 'Ok'
                }
            ]
            })
        await toast.present();
        };




    const openAlertaV2  = async function(head, msg) {
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                cssClass: 'alerta-estandar',
                backdropDismiss: false, 
                buttons: [
                    {
                    text: 'Si',
                    handler: () => { 
                        window.fake_console('en el handler')
                    },
                    },
                ]
            })
        await alerta.present();      
        const {data} = await alerta.onDidDismiss();
        consola(data)
        return 
    }

    const openAlertaBinaria = async function(head, msg, bNo, bSi) {
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                backdropDismiss: false, 
                buttons: [
                    { 
                        text: bNo===undefined ? 'No' : bNo, 
                        role: 'cancel' 
                    },
                    { 
                        text: bSi===undefined ? 'Si' : bSi, 
                        role: 'confirm',
                     },
                ]
            })
        await alerta.present(); 
        const { role } = await alerta.onDidDismiss();
        if (role=='confirm'){return true  } 
        else { return false }
    }




    const OpenAlertaPdvs = async function (pdvs, actual){
        const mis_inputs = pdvs.map( (v) => {
            return {'label' : v.v, 'type' :'radio', 'value' : v.k , 'checked' : v.k==actual}
        })
        const head = `Elegir Pdv`
        const msg = `Elige el pdv que fabricó el cuadro`
        const alerta = await alertController
            .create({
                header: head, 
                message: msg,
                mode : "md",
                cssClass: 'custom-rutas-alert',

                backdropDismiss: false, 
                inputs :mis_inputs, 
                buttons: [
                    {
                      text: 'Cancelar',
                      role: 'cancel',
                      handler: () => {
                        consola('Alert canceled');
                      },
                    },
                    {
                      text: 'OK',   
                      role: 'confirm',
                      handler: ( ) => {
                        consola('Alert confirmed');
                      },
                    },
                  ]
            })
        await alerta.present();
        
        const {data, role} = await alerta.onDidDismiss();
        consola(data)
        if (role=='confirm'){
            return data 
            
        }
        else {
            return false
        }
        
    }





export { openToast ,  openAlertaBinaria, 
        openAlertaV2  , OpenAlertaPdvs

    }


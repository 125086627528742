import { Storage } from '@capacitor/storage';

    const schema = 'cuadros.ges.digital'

    

        const saveExpire = async function(dt) {
            await Storage.set({ key: schema+':ex' , value: dt.ex})
            await Storage.set({ key: schema+':tk' , value: dt.tk})
            window.fake_console(dt.ex, dt.tk)
        }
        const loadExpire = async function() {
            const e = await Storage.get({ key: `${schema}:ex` })
            const t = await Storage.get({ key: `${schema}:tk` })
            if (!e.value ||!t.value ) {
                return undefined 
            }
            else {
                return {'ex': parseInt(e.value) , 'tk': t.value} 
            }
         
        }
export { 
    saveExpire, loadExpire   
    }


<template>
    <ion-header>
        <ion-toolbar  :color="color===undefined ? 'primary' : color " class="ion-justify-content-start ion-align-items-start"> 
            <ion-buttons slot="start" >
                <!-- <ion-button size="default" color="light"  routerLink="/home" routerDirection="none">-->
                 <ion-button size="default" color="light" @click="go">
                    <ion-icon :icon="homeSharp" color="light" class="muy-grande"> </ion-icon>
                </ion-button>
            </ion-buttons>
            <ion-title :style="isIOS ? 'padding-left: 4rem !important;': ''">
                    {{titulo}} 
            </ion-title>
        </ion-toolbar>
    </ion-header>
</template>

<script>
    import { defineComponent } from 'vue';
    import { homeSharp} from 'ionicons/icons';
    export default defineComponent({
        name : "CabeceraComponent",
        data() { return {  homeSharp }},
        props : ['titulo', 'isIOS', 'color'] ,
        methods :{
            go(){
                this.$goHome()
            }
        },
        mounted() { 
            console.log({isIOS: this.isIOS})
            
        },

    })
</script>

<style scoped>

    ion-title {
        width: 80% !important;
        padding-right: 1rem !important;
        float: right !important
    }
    .izquierda {
        text-align:left; 
    }

</style>
<template>
    <div id="qr-code-full-region"></div>
</template>

<script>
    var html5QrcodeScanner
    import { defineComponent } from 'vue';
    import { Html5QrcodeScanner , Html5QrcodeScanType, Html5QrcodeSupportedFormats} from "html5-qrcode";
    export default defineComponent({
        name : "QrcodeScannerComponent",
        props: {
            qrbox: {
            type: Number,
            default: 300
            },
            fps: {
            type: Number,
            default: 10
            },
        },
        mounted () {

            const config = { 
                qrbox : { width: 220, height: 165, aspectRatio :1.333334 }, 
                rememberLastUsedCamera: true,
                // Only support camera scan type.
                supportedScanTypes: [Html5QrcodeScanType.SCAN_TYPE_CAMERA], 
                formatsToSupport : Html5QrcodeSupportedFormats.QR_CODE
                }
            
            html5QrcodeScanner = new Html5QrcodeScanner('qr-code-full-region', config);
            html5QrcodeScanner.render(this.onScanSuccess);            
            /*setTimeout(function(){
                    document.getElementById('html5-qrcode-button-camera-permission').click();
                }, 500)
            */
        },
            methods: {
                onScanSuccess (decodedText, decodedResult) {
                    this.$emit('result', decodedText, decodedResult);
                    html5QrcodeScanner.clear()
                }
            }

    })

/**
 * https://github.com/mebjas/html5-qrcode/tree/master/examples/vuejs
 * 
 * 
 */
</script>
<style scoped>

    #qr-code-full-region{
        margin: auto !important;
        width: 335px !important; 
        height: 250px !important;
    }
    
</style>

//const URL = 'http://localhost:3000'
//const URL = 'https://beta.ges.services'
const URL = 'https://cuadros.ges.partners'
import { createApp } from 'vue'
import App from './App.vue'
import router from './router';
import { IonicVue } from '@ionic/vue';
import eventBus from "@/event-bus";
import {globalFilters} from './utilities/filters'
import { Camera } from '@capacitor/camera';

/**
 * Modelo para utilizar pwa elements 
 * import { defineCustomElements } from '@ionic/pwa-elements/loader'; 
 * defineCustomElements(window);
*/
import { defineCustomElements } from '@ionic/pwa-elements/loader'; 


/* Core CSS required for Ionic components to work properly */
import '@ionic/vue/css/core.css';

/* Basic CSS for apps built with Ionic */
import '@ionic/vue/css/normalize.css';
import '@ionic/vue/css/structure.css';
import '@ionic/vue/css/typography.css';

/* Optional CSS utils that can be commented out */
import '@ionic/vue/css/padding.css';
import '@ionic/vue/css/float-elements.css';
import '@ionic/vue/css/text-alignment.css';
import '@ionic/vue/css/text-transformation.css';
import '@ionic/vue/css/flex-utils.css';
import '@ionic/vue/css/display.css';
import 'purecss/build/pure.css';

import axios from 'axios';
import { Device } from '@capacitor/device';
import { loadExpire } from '@/utilities/services/storage-utilities';
import { openAlertaV2 } from  '@/utilities/services/alert-utilities';


import {    
  IonButton, IonButtons, 
  IonCard, IonCardContent, IonCardHeader, IonCardTitle, IonCardSubtitle,
  IonCol,
  IonContent,
  IonGrid,
  IonFab, IonFabButton,IonFabList, 
  IonFooter,
  IonHeader,
  IonIcon, 
  IonImg,
  IonInput, 
  IonItem, 
  IonLabel,
  IonList, IonListHeader,
  IonNav,
  IonNote,
  IonPage,
  IonRow,
  IonSpinner,
  IonText,
  IonTitle,
  IonToggle,
  IonToolbar,
 } from '@ionic/vue';



/* Theme variables */
import './theme/variables.css';
import './registerServiceWorker'

window.fake_console =   console.log 
//window.fake_console =   function() {void(0)}

//axios.defaults.baseURL = 'http://localhost:3000'
axios.defaults.baseURL = URL
axios.defaults.headers["Content-Type"] = "application/json"
axios.defaults.headers.post['Content-Type'] = 'application/x-www-form-urlencoded';
axios.defaults.headers["Access-Control-Allow-Origin"] = "*"
axios.mode = "no-cors"


/** 
 * Logica de la aplicación 
 * El router no se inicializa hasta que tenemos 
 * un cambio de AuthState en firebase
 * Sino monta las paginas antes de tener el usuario 
 * */

let app = createApp(App)
          .use(IonicVue)
          .use(router);


app.component('ion-button', IonButton);
app.component('ion-buttons', IonButtons);
app.component('ion-card', IonCard);
app.component('ion-card-content', IonCardContent);
app.component('ion-card-header', IonCardHeader);
app.component('ion-card-title', IonCardTitle);
app.component('ion-card-subtitle', IonCardSubtitle);
app.component('ion-col', IonCol);
app.component('ion-content', IonContent);
app.component('ion-fab', IonFab);
app.component('ion-fab-list', IonFabList);
app.component('ion-fab-button', IonFabButton);

app.component('ion-grid', IonGrid);
app.component('ion-footer', IonFooter);
app.component('ion-header', IonHeader);
app.component('ion-icon', IonIcon);
app.component('ion-img', IonImg);
app.component('ion-input', IonInput);
app.component('ion-item', IonItem);
app.component('ion-label', IonLabel);
app.component('ion-list', IonList);
app.component('ion-list-header', IonListHeader);
app.component('ion-nav', IonNav);
app.component('ion-note', IonNote);
app.component('ion-page', IonPage);
app.component('ion-row', IonRow);
app.component('ion-spinner', IonSpinner);  
app.component('ion-text', IonText);
app.component('ion-title', IonTitle);
app.component('ion-toggle', IonToggle);
app.component('ion-toolbar', IonToolbar);
        



/**
 * Variables globales 
 */
var self = app.config.globalProperties
self.$http = axios
self.$isIos = undefined 
self.$filters = globalFilters
self.$dev = undefined
self.$myparams = undefined 
self.$URL = URL



self.$http.interceptors.response.use(
  async res=> {
          if (!res.data || res.data.status_log === false ){
            await openAlertaV2('Credencials invalidas!', `Te dirigimos a la pagina principal ` ) 
            self.$router.replace({name : 'home',  params : { qt: Date.now()}})

            return false
          }
          else {
              return res
          }
      }, 
  async error => {
      if(error.response.status === 404 ) {
          window.fake_console('Estoy en el error del undefined, pasaremos por login  ')
          await openAlertaV2('Credencials invalidas!', `Te dirigimos a la pagina principal ` )          
          self.$router.replace({name : 'home',  params : { qt: Date.now()}})
          return false
      }
  }
)



export {procesoInicial};

const procesoInicial = async function(){
  const device_id = await Device.getId()
  const dev = device_id.uuid 
  const info = await Device.getInfo();
  const isIOS = (info.operatingSystem||'').toLocaleLowerCase() === 'ios'
  self.$isIos = isIOS
  self.$dev = dev 

  eventBus().emitter.emit('cuadros:device',{dev,isIOS });
  const previo  = await loadExpire()
  if (previo && previo.tk) {
    self.$params = {key: previo.tk , dev}
  }
  return 
}

router.isReady().then(async () => {
  window.fake_console('main ','App montada')
  await procesoInicial()
  app.mount('#app');  
  defineCustomElements(window);
  const permisos = await Camera.checkPermissions()
  window.fake_console(permisos)
  if (!permisos) {await Camera.requestPermissions('camera')}

});



self.$goHome = function() {
  self.$router.replace({name : 'home',  params : { qt: Date.now()}})
}

const setParams = async function(t,d) {
  self.$params = {key: t , dev: d }
  return 
}

export {setParams}

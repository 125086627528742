
import { createRouter, createWebHistory } from '@ionic/vue-router';
import eventBus from "@/event-bus";

import HomePage from '@/pages/home-page.vue'
//import ListPage from '@/pages/list-page.vue'

let dev, isIOS

eventBus().emitter.on('cuadros:device',(dl)=>{
  window.fake_console('oido cocina', dl)
  dev = dl.dev;
  isIOS = dl.isIOS
});



const routes = [
  /*
  {
    path: '/',
    component: () => import ('@/pages/home-page.vue')
  },
  {
    path: '/home',
    name: 'home',
    component: HomePage
  }
  */
  {
    path: '/',
    name: 'home',
    component: HomePage,
    props: true
  },
  {
    path: '/:p_pdv([0-9]{3})/:p_otnum([0-9]{1,6})',
    name: 'list',
    props: true,
    component: HomePage
  },
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})



router.beforeEach((to, from, next) => {
  window.fake_console(from)
  window.fake_console(to)
  void(from)
    to.meta.dev = dev;
    to.meta.isIOS = isIOS;
    next();
})




export default router

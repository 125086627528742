
var globalFilters = {    
    // signal_numero
        sMoneda: function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n>0 ? '+' :'')+Math.round(n).toLocaleString('de-DE')+' €'
        },
        //numero
        Moneda : function  (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n).toLocaleString('de-DE')+' €'
        },
        decimales (n){  
            return [undefined, null, 0,].indexOf(n)!=-1 ? '' : ('0'+Math.round((n%1)*100)).slice(-2)
          }
        ,
        
        //entero
        Entero : function  (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n).toLocaleString('de-DE')
        }
        ,
        //signal_entero
        sEntero :function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n>0 ? '+' :'')+Math.round(n).toLocaleString('de-DE')
        }  ,
        //signal_entero
        sPorcentaje :function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n*100).toLocaleString('de-DE', {minimumFractionDigits: 1, maximumFractionDigits: 1})+' %'
        }  
        ,
        //Entero mostrado como porcentaje
        sIntPorcentaje :function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : (n).toLocaleString('de-DE', {minimumFractionDigits: 0, maximumFractionDigits: 0})+' %'
        }  ,
        sDecimal: function (n){
            return [undefined, null, 0,].indexOf(n)!=-1 ? '-' : Math.round(n).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        sDecimalRenderer: function (n){
            return [undefined, null, 0,].indexOf(n.value)!=-1 ? '-' : Math.round(n.value).toLocaleString('de-DE', {minimumFractionDigits: 2, maximumFractionDigits: 2})
        },
        Capitalize : function(n) {
            if (typeof n !== 'string') return ''
            return n.charAt(0).toUpperCase() + n.slice(1).toLowerCase()
          },
        num2Month : function(n){
            if (Number.isNaN(n)||n===undefined ) return '-'
            else 
                {            
                    const x = n.toString()
                    return `${x.slice(0,4)}.${x.slice(4,6)}`
                }
        }
    }
    function initcap(t) {
        return t[0].toUpperCase()+(t.slice(1).toLowerCase())
    }
    
export { globalFilters, initcap  }

<template>
  <ion-app>
        <FontAwesomeLibraryComponent/>
        <ion-content id="main-content" scroll-y ="true" >
          <router-view ></router-view>
        </ion-content>
  </ion-app>

</template>

<script>
import { IonApp, IonContent } from '@ionic/vue'

import { defineComponent } from 'vue';
import FontAwesomeLibraryComponent from '@/components/FontAwesomeLibraryComponent.vue'


export default defineComponent({
  name: 'App',
  data() {
    return {
      deferredPrompt: null, 
      intentos : 0 
    }
  },  
  components: {
    IonApp,  IonContent ,FontAwesomeLibraryComponent
  }, 
 
});
</script>
<style lang="scss" >

  @import "@/theme/main-styles.scss";
  @import "@/theme/icon-styles.scss";
  @import "@/theme/alert-popover-styles.scss";
  @import "@/theme/botones-etiqueta.scss";


</style>